import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Home from "../components/Home";

function IndexPage({ location, data }) {
  return (
    <Layout location={location} languages={data.site.siteMetadata.languages}>
      <Home messages={data.es} />
    </Layout>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query PageEsUsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    es: datoCmsHome(locale: { eq: "es" }) {
      title
      subtitle
    }
  }
`;
